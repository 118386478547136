import styled from 'styled-components';

export const UpdateImage = styled.input`
  display: none;
`;

export const BoxButtonAdd = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 1.2em;
`

export const ButtonUpdateImage = styled.button`
  border-radius: 0.5rem;
  border: 2px dotted var(--color-BluePrimary);
  color: var(--color-BluePrimary);
  margin: 1rem;
  padding: 2rem 1rem;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--color-BluePrimary);
  };
`
