import { IoIosCloseCircle } from "react-icons/io";

import {
  ContainerImagemViewer,
  DeleteButton,
  ImageThumbnail,
  MainButton
} from './styles/ImageUploadThumbnails.styles';

interface ImageUploadThumbnailsProps {
  imageUrl: string;
  isMain: boolean;
  onRemove: () => void;
  onSetMain: () => void;
}

const ImageUploadThumbnails = ({ imageUrl, isMain, onRemove, onSetMain }: ImageUploadThumbnailsProps) => {
  return (
    <ContainerImagemViewer>
      <ImageThumbnail src={imageUrl} alt={'Imagem do veículo'} />
      <DeleteButton onClick={onRemove} aria-label='Remover Imagem'>
        <IoIosCloseCircle />
      </DeleteButton>
      <MainButton
        onClick={(e) => {
          e.preventDefault();
          onSetMain();
        }}
        aria-label='Definir como imagem principal'
        style={{ 
          backgroundColor: isMain ? 'var(--color-BluePrimary)' : 'white',
          color: isMain ? 'white' : 'var(--color-BluePrimary)'
        }}
      >
        Principal
      </MainButton>
    </ContainerImagemViewer>
  );
};

export default ImageUploadThumbnails;