import { useRef } from 'react';
import { BoxButtonAdd, ButtonUpdateImage, UpdateImage } from './Styles/InputUploadImages.styles';
import { RiImageAddLine } from "react-icons/ri";

interface InputUploadImagesProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputUploadImages = ({ onChange }: InputUploadImagesProps) => {

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <UpdateImage
        type="file"
        multiple
        accept='image/*'
        ref={fileInputRef}
        onChange={onChange}
      />
      <ButtonUpdateImage onClick={handleButtonClick}>
        <RiImageAddLine size={32} />
        <BoxButtonAdd>
          <p>Adicionar Imagem</p>
        </BoxButtonAdd>
      </ButtonUpdateImage>
    </>
  )
};

export default InputUploadImages;