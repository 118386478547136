import styled from 'styled-components';

export const ContainerImagemViewer = styled.div`
  position: relative;
`

export const ImageThumbnail = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`

export const DeleteButton = styled.button`
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  color: var(--color-BluePrimary);
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 28px;
  transition: 30ms;

  &:hover {
    color: red;
  }
`
export const MainButton = styled.button`
  position: absolute;
  bottom: 3px;
  left: 0;
  translate: calc(100%);
  background-color: white; /* Cor padrão */
  color: var(--color-BluePrimary);
  border: 2px dotted var(--color-BluePrimary);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    border: 2px solid var(--color-BluePrimary);
  }
`