import styled from 'styled-components';

export const ContainerUpload = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ImagesWrapper = styled.div`
  position: relative;
`;

export const ImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  

  img {
    width: 350px;
    height: 300px;
    border-radius: 5px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
`;

export const ButtonRemove = styled.button`
  position: absolute;
  top: -17px;
  right: -17px;
  background-color: transparent;
  color: red;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
`;

export const ButtonMain = styled.button`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f26241;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;
