import React, { useState } from 'react';
import { apiClient } from '../../../../../Services/apiClient';
import {
  Container,
  ImagePreviewContainer,
  UploadButton,
} from './styles/UploadImages.styles';

import { useNavigate } from 'react-router-dom';

import imageCompression from 'browser-image-compression';
import InputUploadImages from '../../../../../Components/Inputs/InputUploadImages/InputUploadImages';
import ImageUploadThumbnails from '../../../../../Components/ImageThumbnails/ImageUploadThumbnails/ImageUploadThumbnails';

interface UploadImagesProps {
  vehicleId: number | null;
}

interface ImagePreview {
  file: File;
  is_main: boolean;
  previewUrl: string;
};

const UploadImages = ({ vehicleId }: UploadImagesProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<ImagePreview[]>([]);
  const [mainImageIndex, setMainImageIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files) {
      console.error('Nenhum arquivo selecionado.');
      return;
    }

    const filesArray = Array.from(files);
    const compressedFiles: File[] = [];
    const updatedImagePreviews: ImagePreview[] = []; 

    for (const file of filesArray) {
      if (!file.type.startsWith('image/')) {
        alert(`${file.name} não é uma imagem válida.`);
        continue;
      }

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        compressedFiles.push(compressedFile);
        const previewUrl = URL.createObjectURL(compressedFile);

        // Adiciona a imagem ao array de previews
        updatedImagePreviews.push({ file: compressedFile, is_main: false, previewUrl });
      } catch (error) {
        console.error('Erro ao comprimir a imagem:', error);
        alert(`Erro ao comprimir a imagem ${file.name}.`);
      }
    }

    if (compressedFiles.length > 0) {
      setSelectedFiles(compressedFiles);
      setImagePreviews(updatedImagePreviews); // Atualiza o estado com os previews
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFiles.length || !vehicleId) {
      alert('Selecione pelo menos uma imagem e crie o veículo.');
      return;
    }

    const formData = new FormData();

    selectedFiles.forEach((image, index) => {
      formData.append('images', image, `image-${index}.jpg`);
      formData.append('isMain', index === mainImageIndex ? 'true' : 'false'); // Define 'isMain' como 'true' apenas para a imagem principal
    });

    setLoading(true);

    try {
      const response = await apiClient.post(`/vehicles/${vehicleId}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Imagens enviadas com sucesso!');
      setSelectedFiles([]);
      setImagePreviews([]);
      setMainImageIndex(null);
      navigate('/');
    } catch (error) {
      console.error('Erro ao enviar imagens:', error);
      alert('Erro ao enviar as imagens. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    const updatedPreviews = [...imagePreviews];

    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);

    // Ajusta a imagem principal se necessário
    if (mainImageIndex === index) {
      setMainImageIndex(null);
    } else if (mainImageIndex && mainImageIndex > index) {
      setMainImageIndex(mainImageIndex - 1);
    }

    setSelectedFiles(updatedFiles);
    setImagePreviews(updatedPreviews);
  };

  const handleSetMainImage = (index: number) => {
    // Atualiza o índice da imagem principal
    setMainImageIndex(index);

    // Atualiza o estado das imagens para refletir a mudança de is_main
    setImagePreviews((prevImages) =>
      prevImages.map((image, i) => ({
        ...image,
        is_main: i === index, // Define is_main como true para a imagem clicada, false para as demais
      }))
    );
  };

  return (
    <Container>
      <ImagePreviewContainer>
        {imagePreviews.map((preview, index) => (
          <ImageUploadThumbnails
            key={index}
            imageUrl={preview.previewUrl}
            isMain={mainImageIndex === index}
            onRemove={() => handleRemoveImage(index)}
            onSetMain={() => handleSetMainImage(index)}
          />
        ))}
        <InputUploadImages onChange={handleFileChange} />
      </ImagePreviewContainer>
      <UploadButton onClick={handleImageUpload} disabled={selectedFiles.length === 0 || vehicleId === null || loading}>
        {loading ? 'Carregando...' : 'Enviar Imagens'}
      </UploadButton>
    </Container>
  );
};

export default UploadImages;
