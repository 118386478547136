import InputUploadImages from '../../Components/Inputs/InputUploadImages/InputUploadImages';
import Manutencao from '../Manuten/manuten';

const Dashboard = () => {

  return (
    <>
    <Manutencao/>
    
    </>
  );
};

export default Dashboard;
