import { CircularProgress, Box, Typography } from "@mui/material";

export const Manutencao = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f4f4f4"
    >
      <CircularProgress size={80} color="primary" />
      <Typography
        variant="h4"
        mt={3}
        fontWeight="bold"
        color="primary"
        textAlign="center"
      >
        Estamos em manutenção! 🚧
      </Typography>
      <Typography variant="body1" mt={1} color="textSecondary" textAlign="center">
        Voltamos em breve com novidades. Obrigado pela paciência! 😊
      </Typography>
      <ul>
        <li>Update veículo finalização 25/02/2024 ás 16hrs</li>
        <li>Formulário de vendas 25/02/2024 ás 16hrs</li>
        <li>Relátorio de vendas 25/02/2024 ás 16hrs</li>
      </ul>
    </Box>
  );
};

export default Manutencao;
