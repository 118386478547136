import { useState, useEffect } from 'react';

import { apiClient } from '../Services/apiClient';

interface ImagePreview {
  file: File;
  is_main: boolean;
  previewUrl: string;
  imageId: string;
  toRemove?: boolean;
}

interface UpdateVehicleRequest {
  id: string;
  title: string;
  description: string;
  mileage: string;
  displacement: string;
  acceptsExchange: boolean;
  price: number;
  year?: string;
  color?: string;
  status?: boolean;
  brand?: string;
  type?: string;
  extraIds: string[];
  vehicleExtras?: { extrasItem_id: string }[];
  images: ImagePreview[];
};

interface UseUpdateVehicleProps {
  vehicleId: string;
  onSubmitSuccess: () => void;
};

export const useUpdateVehicle = ({ vehicleId, onSubmitSuccess }: UseUpdateVehicleProps) => {
  const [vehicle, setVehicle] = useState<Partial<UpdateVehicleRequest>>({
    displacement: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});


  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        setLoading(true);
        // Primeira requisição para pegar os detalhes do veículo
        const response = await apiClient.get(`vehicles/${vehicleId}`);
        const data = response.data;

        // Adiciona os ids extras
        const extraIds = data.vehicleExtras.map((extra: any) => extra.extrasItem_id);

        // Requisição para pegar as imagens
        const imageResponse = await apiClient.get(`vehicles/${vehicleId}`);
        const images = imageResponse.data.images?.map((image: any) => ({
          file: new File([], image.image_url),  // Usando a URL da imagem para criar o arquivo
          is_main: image.is_main,
          previewUrl: image.image_url,  // A URL da imagem para o preview
          imageId: image.id,
        })) || [];

        console.log(images)

        // Atualiza o estado com as imagens e os detalhes do veículo
        setVehicle({
          ...data,
          extraIds: extraIds,
          images: images,  // Incluindo as imagens no estado
        });
      } catch (err) {
        console.error('Error fetching Vehicle', err);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicle();
  }, [vehicleId]);



  const handleChange = (field: keyof UpdateVehicleRequest, value: any) => {
    setVehicle((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleItemChange = (selectedIds: string[] | null) => {
    setVehicle((prev) => ({
      ...prev,
      extraIds: selectedIds || [],
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrors({});

      // Filtrar as imagens que estão marcadas para remoção
      const imagesToRemove = vehicle.images?.filter((image) => image.toRemove) || [];
      const validImages = vehicle.images?.filter((image) => !image.toRemove) || [];

      // Primeiro, excluir as imagens marcadas como removidas
      if (imagesToRemove.length > 0) {
        for (const image of imagesToRemove) {
          // Remover a imagem do backend usando o ID
          await apiClient.delete(`/images/${image.imageId}`);
        }
      }

      // Enviar a atualização do veículo (com as imagens restantes)
      const updatedVehicle = {
        ...vehicle,
        images: validImages,  // Somente as imagens que não foram removidas
      };

      // Atualizar o veículo no backend
      await apiClient.put(`vehicle/updateVehicle/${vehicleId}`, updatedVehicle);

      // Chamar a função de sucesso
      onSubmitSuccess();
    } catch (err: any) {
      console.error(err);
      setErrors(err.response?.data?.errors || {});
    } finally {
      setLoading(false);
    }
  };


  const handleRemoveImage = (index: number) => {
    if (vehicleId && vehicle.images) {
      const updatedImages = [...vehicle.images];
      const image = updatedImages[index]; // Obtendo a imagem selecionada pelo índice

      if (!image?.imageId) {
        console.error('Imagem não possui um ID válido!');
        return;
      }

      // Marcar a imagem para remoção (remover de forma fictícia)
      image.toRemove = true;

      // Atualizar o estado com a imagem marcada para remoção
      setVehicle((prev) => ({
        ...prev,
        images: updatedImages,
      }));
    }
  };


  const handleSetMainImage = async (index: number, event?: React.MouseEvent) => {
    // Evita comportamento padrão se houver um evento
    if (event) event.preventDefault();
  
    if (vehicle.images) {
      // Verifica se a imagem já é a principal para evitar re-render desnecessário
      if (vehicle.images[index].is_main) return;
  
      const updatedImages = vehicle.images.map((image, i) => ({
        ...image,
        is_main: i === index,
      }));
  
      // Atualiza o estado apenas se houver mudança
      setVehicle((prev) => ({
        ...prev,
        images: updatedImages,
      }));
  
      if (vehicleId) {
        try {
          await apiClient.patch(`/vehicles/${vehicleId}/images`, {
            mainImageIndex: index,
          });
        } catch (error) {
          console.error('Erro ao atualizar imagem principal:', error);
        }
      }
    }
  };

  return {
    vehicle,
    loading,
    errors,
    handleChange,
    handleSubmit,
    handleItemChange,
    handleRemoveImage,
    handleSetMainImage,
  };
};